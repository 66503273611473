import { NewPlanDetails, StripePlan } from "../types";
import { stripePriceIds } from "./constants";

const newPlansPriceIds = [
  // new test prices ids
  ...stripePriceIds.testInDepthInterviews500,
  ...stripePriceIds.testInDepthInterviews1000,
  ...stripePriceIds.testInDepthInterviews2000,
  ...stripePriceIds.testInDepthInterviews5000,
  ...stripePriceIds.testInDepthInterviews42,
  ...stripePriceIds.testInDepthInterviews83,
  ...stripePriceIds.testInDepthInterviews167,
  ...stripePriceIds.testInDepthInterviews417,
  ...stripePriceIds.testInterviewsRag500,
  ...stripePriceIds.testInterviewsRag1000,
  ...stripePriceIds.testInterviewsRag2000,
  ...stripePriceIds.testInterviewsRag5000,
  ...stripePriceIds.testSurveys200,
  ...stripePriceIds.testSurveys500,
  ...stripePriceIds.testSurveys1200,
  ...stripePriceIds.testSurveys6000,
  ...stripePriceIds.testSurveys12000,
  // new prod prices ids
  ...stripePriceIds.prodInDepthInterviews500,
  ...stripePriceIds.prodInDepthInterviews1000,
  ...stripePriceIds.prodInDepthInterviews2000,
  ...stripePriceIds.prodInDepthInterviews5000,
  ...stripePriceIds.prodInDepthInterviews42,
  ...stripePriceIds.prodInDepthInterviews83,
  ...stripePriceIds.prodInDepthInterviews167,
  ...stripePriceIds.prodInDepthInterviews417,
  ...stripePriceIds.prodInterviewsRag500,
  ...stripePriceIds.prodInterviewsRag1000,
  ...stripePriceIds.prodInterviewsRag2000,
  ...stripePriceIds.prodInterviewsRag5000,
  ...stripePriceIds.prodSurveys200,
  ...stripePriceIds.prodSurveys500,
  ...stripePriceIds.prodSurveys1200,
  ...stripePriceIds.prodSurveys6000,
  ...stripePriceIds.prodSurveys12000,
];

/**
 * Check if a price is a new plan price
 * @param priceId - The price ID to check
 * @returns True if the price is a new plan price, false otherwise
 */
export const isNewPlanPrice = (priceId: string) => {
  return newPlansPriceIds.includes(priceId);
};

/**
 * Check if a plan is a new plan
 * @param plan - The plan to check
 * @returns True if the plan is a new plan, false otherwise
 */
const isNewPlan = (plan: StripePlan) => {
  return plan.prices.some((price) => isNewPlanPrice(price.id));
};

/**
 * Handle legacy plans
 * @param stripePlans - The plans from Stripe
 * @returns The plans
 */
export const handleNewPlans = (
  stripePlans: StripePlan[]
):
  | { surveys?: NewPlanDetails; interviews?: NewPlanDetails; interviewsRag?: NewPlanDetails }
  | undefined => {
  const newPlans = stripePlans.filter((plan) => isNewPlan(plan));

  const surveysPlan = newPlans.find((plan) => plan.metadata.plan_type === "surveys");
  const interviewsPlan = newPlans.find((plan) => plan.metadata.plan_type === "in_depth_interviews");
  const interviewsRagPlan = newPlans.find((plan) => plan.metadata.plan_type === "interviews_rag");

  const surveys: NewPlanDetails | undefined = surveysPlan
    ? {
        type: surveysPlan?.metadata.plan_type || "",
        title: surveysPlan?.name || "",
        description: "For individuals and small teams",
        price_options: {
          annual: surveysPlan.prices
            .filter((price) => price.recurring?.interval === "year")
            .map((price) => ({
              stripe_id: price.id,
              seats_limit: parseInt(surveysPlan?.metadata.seats_limit || "0"),
              custom_script_max_questions: parseInt(
                surveysPlan?.metadata.custom_script_max_questions || "0"
              ),
              price: price.unit_amount ? price.unit_amount / 100 : 0,
              saving: 0,
              surveys_limit: parseInt(price?.metadata.surveys_limit || "0"),
              interviews_limit: undefined,
            })),
          monthly: surveysPlan.prices
            .filter((price) => price.recurring?.interval === "month")
            .map((price) => ({
              stripe_id: price.id,
              seats_limit: parseInt(surveysPlan?.metadata.seats_limit || "0"),
              custom_script_max_questions: parseInt(
                surveysPlan?.metadata.custom_script_max_questions || "0"
              ),
              price: price.unit_amount ? price.unit_amount / 100 : 0,
              saving: 0,
              surveys_limit: parseInt(price?.metadata.surveys_limit || "0"),
            })),
        },
        most_popular: surveysPlan.default_price,
        included_features: [],
      }
    : undefined;

  const interviews: NewPlanDetails | undefined = interviewsPlan
    ? {
        type: interviewsPlan?.metadata.plan_type || "",
        title: interviewsPlan?.name || "",
        description: "For research teams",
        price_options: {
          annual: interviewsPlan.prices
            .filter((price) => price.recurring?.interval === "year")
            .map((price) => ({
              stripe_id: price.id,
              seats_limit: parseInt(interviewsPlan?.metadata.seats_limit || "0"),
              custom_script_max_questions: parseInt(
                interviewsPlan?.metadata.custom_script_max_questions || "0"
              ),
              price: price.unit_amount ? price.unit_amount / 100 : 0,
              saving: 0,
              interviews_limit: parseInt(price?.metadata.interviews_limit || "0"),
            })),
          monthly: interviewsPlan.prices
            .filter((price) => price.recurring?.interval === "month")
            .map((price) => ({
              stripe_id: price.id,
              seats_limit: parseInt(interviewsPlan?.metadata.seats_limit || "0"),
              custom_script_max_questions: parseInt(
                interviewsPlan?.metadata.custom_script_max_questions || "0"
              ),
              price: price.unit_amount ? price.unit_amount / 100 : 0,
              saving: 0,
              interviews_limit: parseInt(price?.metadata.interviews_limit || "0"),
            })),
        },
        most_popular: interviewsPlan.default_price,

        included_features:
          "features" in interviewsPlan && Array.isArray(interviewsPlan.features)
            ? interviewsPlan.features?.map((feature: { name: string }) => feature.name) || []
            : [],
      }
    : undefined;

  const interviewsRag: NewPlanDetails | undefined = interviewsRagPlan
    ? {
        type: interviewsRagPlan?.metadata.plan_type || "",
        title: interviewsRagPlan?.name || "",
        description: "Rag for in-depth interviews",
        price_options: {
          annual: interviewsRagPlan.prices
            .filter((price) => price.recurring?.interval === "year")
            .map((price) => ({
              stripe_id: price.id,
              price: price.unit_amount ? price.unit_amount / 100 : 0,
              interviews_limit: parseInt(price?.metadata.interviews_limit || "0"),
            })),
          monthly: [],
        },
      }
    : undefined;

  return {
    surveys,
    interviews,
    interviewsRag,
  };
};
