import { resetWorkspace } from "@/store/modules/workspaces/slice";
import { store } from "../..";
import { resetAudiences } from "../../modules/audiences/slice";
import { resetCustomScript } from "../../modules/custom-script/slice";
import { resetHistory } from "../../modules/history/slice";
import { knowledgeGraphApi } from "../../modules/knowledge-graph/slice";
import { resetProblems } from "../../modules/problems/slice";
import { resetRag } from "../../modules/rag/slice";
import { resetResearchAssistant } from "../../modules/research-assistant/slice";
import { resetResearchGoal } from "../../modules/research-goal/slice";
import { resetSolutions } from "../../modules/solutions/slice";
import { resetStudy } from "../../modules/study/slice";
import { resetSummaryConversations } from "../../modules/summaries-conversations/slice";
import { resetSummary } from "../../modules/summaries/slice";
import { resetSurveys } from "../../modules/surveys/actions";
import { resetSyntheticUsers } from "../../modules/synthetic-users/slice";
import { resetUserInterviewConversations } from "../../modules/user-interview-conversations/slice";
import { resetUserInterviews } from "../../modules/user-interviews/slice";
import { resetProject } from "@/store/modules/projects/slice";
import { plansApi } from "@/store/modules/plans/slice";
import { ssoProviderApi } from "@/store/modules/sso-provider/slice";
import { subscriptionApi } from "@/store/modules/subscription/slice";
import { resetCurrentUser } from "@/store/modules/users/slice";

export const resetAllData = () => {
  store.dispatch(resetAudiences());
  store.dispatch(resetProblems());
  store.dispatch(resetSolutions());
  store.dispatch(resetResearchAssistant());
  store.dispatch(resetCustomScript());
  store.dispatch(resetResearchGoal());
  store.dispatch(resetStudy());
  store.dispatch(resetSyntheticUsers());
  store.dispatch(resetUserInterviews());
  store.dispatch(resetUserInterviewConversations());
  store.dispatch(resetSummary());
  store.dispatch(resetSummaryConversations());
  store.dispatch(knowledgeGraphApi.util.invalidateTags(["KnowledgeGraph"]));
  store.dispatch(plansApi.util.invalidateTags(["Plans"]));
  store.dispatch(ssoProviderApi.util.invalidateTags(["SsoProvider"]));
  store.dispatch(subscriptionApi.util.invalidateTags(["Subscription"]));
  store.dispatch(resetHistory());
  store.dispatch(resetRag());
  store.dispatch(resetSurveys());
  store.dispatch(resetProject());
  store.dispatch(resetWorkspace());
  store.dispatch(resetCurrentUser());
};
