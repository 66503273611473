// Redux Toolkit
import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import { StudyPlan, StudySubPlan, Suggestion } from "./types";
// Schemas
import { StudyPlanSchema, StudySubPlanSchema, SuggestionSchema } from "./schemas";
// Initial state
import { initialState } from "./initial-state";
import { z } from "zod";
import { sdkApis } from "@/store/api-sdk";

// Create the API slice
export const studyPlansApi = createApi({
  reducerPath: "studyPlansApi",
  baseQuery: customBaseQuery,
  tagTypes: ["StudyPlans"],
  endpoints: (builder) => ({
    getPlansList: builder.query<StudyPlan[], { projectId: string }>({
      query: () => ({
        type: "sdk",
        method: sdkApis.plans.listPlans(),
      }),
      // TODO: replace with query params once backend is updated
      transformResponse: (response: StudyPlan[], _, arg) =>
        response
          .filter((plan) => plan.projectId === arg.projectId)
          .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
      extraOptions: {
        dataSchema: z.array(StudyPlanSchema),
      },
    }),
    getPlanById: builder.query<StudyPlan, { planId: string }>({
      query: ({ planId }) => ({
        type: "sdk",
        method: sdkApis.plans.getPlan(planId),
      }),
      extraOptions: {
        dataSchema: StudyPlanSchema,
      },
    }),
    createPlan: builder.mutation<
      StudyPlan,
      { projectId: string; audience: string; researchGoal: string }
    >({
      query: ({ projectId, audience, researchGoal }) => ({
        type: "sdk",
        method: sdkApis.plans.createPlan({
          projectId,
          audience,
          researchGoal,
        }),
      }),
      extraOptions: {
        dataSchema: StudyPlanSchema,
      },
    }),
    editPlan: builder.mutation<
      StudyPlan,
      {
        planId: string;
        name: string;
        audience: string;
        researchGoal: string;
        graphData: Record<string, unknown>;
      }
    >({
      query: ({ planId, name, audience, researchGoal, graphData }) => ({
        type: "sdk",
        method: sdkApis.plans.updatePlan(planId, {
          name,
          audience,
          researchGoal,
          graphData,
        }),
      }),
      extraOptions: {
        dataSchema: StudyPlanSchema,
      },
    }),
    createSubPlan: builder.mutation<
      StudySubPlan,
      { planId: string; parentId: string | null; maxStudy: number }
    >({
      query: ({ planId, parentId, maxStudy }) => ({
        type: "sdk",
        method: sdkApis.subPlans.createSubplan({
          planId,
          parentId,
          maxStudy,
        }),
      }),
      extraOptions: {
        dataSchema: StudySubPlanSchema,
      },
    }),
    getSubPlanById: builder.query<StudySubPlan, { subPlanId: string }>({
      query: ({ subPlanId }) => ({
        type: "sdk",
        method: sdkApis.subPlans.getSubplan(subPlanId),
      }),
      extraOptions: {
        dataSchema: StudySubPlanSchema,
      },
    }),
    generateSubPlanReport: builder.mutation<StudySubPlan, { subPlanId: string }>({
      query: ({ subPlanId }) => ({
        type: "sdk",
        method: sdkApis.subPlans.generateSubplanReport(subPlanId),
      }),
      extraOptions: {
        dataSchema: StudySubPlanSchema,
      },
    }),
    executeSuggestedStudy: builder.mutation<Suggestion, { suggestionId: string }>({
      query: ({ suggestionId }) => ({
        type: "sdk",
        method: sdkApis.suggestions.executeSuggestion(suggestionId),
      }),
      extraOptions: {
        dataSchema: SuggestionSchema,
      },
    }),
    getSuggestedStudies: builder.query<Suggestion[], { suggestionIds: string[] }>({
      query: ({ suggestionIds }) => ({
        type: "sdk",
        method: sdkApis.suggestions.listSuggestions(suggestionIds.join(",")),
      }),
      extraOptions: {
        dataSchema: z.array(SuggestionSchema),
      },
    }),
    deleteSuggestedStudy: builder.mutation<void, { suggestionId: string }>({
      query: ({ suggestionId }) => ({
        type: "sdk",
        method: sdkApis.suggestions.deleteSuggestion(suggestionId),
      }),
    }),
    updateSuggestedStudy: builder.mutation<Suggestion, { suggestionId: string; quantity: number }>({
      query: ({ suggestionId, quantity }) => ({
        type: "sdk",
        method: sdkApis.suggestions.updateSuggestion(suggestionId, {
          quantity,
        }),
      }),
      extraOptions: {
        dataSchema: SuggestionSchema,
      },
    }),
  }),
});

// Create the regular slice
export const studyPlansSlice = createSlice({
  name: "studyPlans",
  initialState,
  reducers: {
    /***** --- Reset Study Plans --- *****/
    resetStudyPlan: (state) => {
      state.data.studyPlan = initialState.data.studyPlan;
      state.loading = initialState.loading;
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        isAnyOf(
          studyPlansApi.endpoints.getPlansList.matchPending,
          studyPlansApi.endpoints.getPlanById.matchPending,
          studyPlansApi.endpoints.createPlan.matchPending,
          studyPlansApi.endpoints.editPlan.matchPending,
          studyPlansApi.endpoints.createSubPlan.matchPending,
          studyPlansApi.endpoints.getSubPlanById.matchPending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          studyPlansApi.endpoints.getPlansList.matchFulfilled,
          studyPlansApi.endpoints.getPlansList.matchRejected,
          studyPlansApi.endpoints.getPlanById.matchFulfilled,
          studyPlansApi.endpoints.getPlanById.matchRejected,
          studyPlansApi.endpoints.createPlan.matchFulfilled,
          studyPlansApi.endpoints.createPlan.matchRejected,
          studyPlansApi.endpoints.editPlan.matchFulfilled,
          studyPlansApi.endpoints.editPlan.matchRejected,
          studyPlansApi.endpoints.createSubPlan.matchFulfilled,
          studyPlansApi.endpoints.createSubPlan.matchRejected,
          studyPlansApi.endpoints.getSubPlanById.matchFulfilled,
          studyPlansApi.endpoints.getSubPlanById.matchRejected
        ),
        (state) => {
          state.loading = false;
        }
      )
      .addMatcher(studyPlansApi.endpoints.getPlansList.matchFulfilled, (state, action) => {
        state.data.studyPlansList = action.payload;
      })
      .addMatcher(studyPlansApi.endpoints.editPlan.matchFulfilled, (state, action) => {
        state.data.studyPlansList = state.data.studyPlansList?.map((plan) =>
          plan.id === action.payload.id ? action.payload : plan
        );
      })
      .addMatcher(
        isAnyOf(
          studyPlansApi.endpoints.getPlanById.matchFulfilled,
          studyPlansApi.endpoints.createPlan.matchFulfilled
        ),
        (state, action) => {
          state.data.studyPlan = action.payload;
        }
      )
      .addMatcher(studyPlansApi.endpoints.createSubPlan.matchFulfilled, (state, action) => {
        state.data.studyPlan?.subplans.push(action.payload);
      })
      .addMatcher(studyPlansApi.endpoints.generateSubPlanReport.matchFulfilled, (state, action) => {
        const updatedSubPlan = action.payload;
        if (state.data.studyPlan) {
          state.data.studyPlan = {
            ...state.data.studyPlan,
            subplans: state.data.studyPlan?.subplans.map((subPlan) =>
              subPlan.id === updatedSubPlan.id ? updatedSubPlan : subPlan
            ),
          };
        }
      })
      .addMatcher(studyPlansApi.endpoints.getSubPlanById.matchFulfilled, (state, action) => {
        const subPlanResponse = action.payload;
        const subPlanExists = state.data.studyPlan?.subplans.find(
          (subPlan) => subPlan.id === subPlanResponse.id
        );

        if (subPlanExists && state.data.studyPlan) {
          state.data.studyPlan = {
            ...state.data.studyPlan,
            subplans: state.data.studyPlan?.subplans.map((subPlan) =>
              subPlan.id === subPlanResponse.id ? subPlanResponse : subPlan
            ),
          };
        } else {
          state.data.studyPlan?.subplans.push(action.payload);
        }
      })
      .addMatcher(studyPlansApi.endpoints.getSuggestedStudies.matchFulfilled, (state, action) => {
        const suggestionsResponse = action.payload;

        if (state.data.studyPlan) {
          state.data.studyPlan = {
            ...state.data.studyPlan,
            subplans: state.data.studyPlan?.subplans.map((subPlan) => ({
              ...subPlan,
              suggestions: subPlan.suggestions.map((suggestion) => {
                const updatedSuggestion = suggestionsResponse.find(
                  (responseSuggestion) => responseSuggestion.id === suggestion.id
                );
                return updatedSuggestion ? updatedSuggestion : suggestion;
              }),
            })),
          };
        }
      })
      .addMatcher(studyPlansApi.endpoints.executeSuggestedStudy.matchFulfilled, (state, action) => {
        const suggestionResponse = action.payload;

        if (state.data.studyPlan) {
          state.data.studyPlan = {
            ...state.data.studyPlan,
            subplans: state.data.studyPlan?.subplans.map((subPlan) => ({
              ...subPlan,
              suggestions: subPlan.suggestions.map((suggestion) =>
                suggestion.id === suggestionResponse.id ? suggestionResponse : suggestion
              ),
            })),
          };
        }
      })
      .addMatcher(studyPlansApi.endpoints.deleteSuggestedStudy.matchFulfilled, (state, action) => {
        const suggestionId = action.meta.arg.originalArgs.suggestionId;

        if (state.data.studyPlan) {
          state.data.studyPlan = {
            ...state.data.studyPlan,
            subplans: state.data.studyPlan?.subplans.map((subPlan) => ({
              ...subPlan,
              suggestions: subPlan.suggestions.filter(
                (suggestion) => suggestion.id !== suggestionId
              ),
            })),
          };
        }
      })
      .addMatcher(studyPlansApi.endpoints.updateSuggestedStudy.matchFulfilled, (state, action) => {
        const updatedSuggestion = action.payload;

        if (state.data.studyPlan) {
          state.data.studyPlan = {
            ...state.data.studyPlan,
            subplans: state.data.studyPlan?.subplans.map((subPlan) => ({
              ...subPlan,
              suggestions: subPlan.suggestions.map((suggestion) =>
                suggestion.id === updatedSuggestion.id ? updatedSuggestion : suggestion
              ),
            })),
          };
        }
      })
      /***** --- Handle Errors --- *****/
      .addMatcher(
        isAnyOf(
          studyPlansApi.endpoints.getPlansList.matchRejected,
          studyPlansApi.endpoints.getPlanById.matchRejected,
          studyPlansApi.endpoints.createPlan.matchRejected,
          studyPlansApi.endpoints.editPlan.matchRejected,
          studyPlansApi.endpoints.createSubPlan.matchRejected,
          studyPlansApi.endpoints.getSubPlanById.matchRejected
        ),
        (state, action) => {
          state.error = parseError(action.error);
        }
      );
  },
});

// Export actions
export const { resetStudyPlan } = studyPlansSlice.actions;

// export hooks
export const {
  useGetPlansListQuery,
  useGetPlanByIdQuery,
  useCreatePlanMutation,
  useEditPlanMutation,
  useCreateSubPlanMutation,
  useGetSubPlanByIdQuery,
  useExecuteSuggestedStudyMutation,
  useGetSuggestedStudiesQuery,
  useDeleteSuggestedStudyMutation,
  useUpdateSuggestedStudyMutation,
  useGenerateSubPlanReportMutation,
} = studyPlansApi;

// Combine the reducers
export const studyPlansReducer = {
  [studyPlansApi.reducerPath]: studyPlansApi.reducer,
  studyPlans: studyPlansSlice.reducer,
};
