import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { EnvironmentVariables } from "logic/internals/runtime/environment-variables";
import { Logger } from "logic/internals/logging/logger";
import { getFirebaseAuth } from "logic/internals/apis/firebase/firebase-auth";

export const baseFetchQuery = fetchBaseQuery({
  baseUrl: EnvironmentVariables.MAIN_API_URL,
  prepareHeaders: async (headers, api) => {
    const extraOptions = api.extra as { skipAuthentication?: boolean };

    // Add authentication token if available
    if (!extraOptions?.skipAuthentication) {
      try {
        const currentUser = getFirebaseAuth().currentUser;
        if (currentUser) {
          const token = await currentUser.getIdToken();
          headers.set("Authorization", `Bearer ${token}`);
        }
      } catch (error) {
        Logger.logError("customBaseQuery:fetch:token-error", error);
      }
    }
    return headers;
  },
});
