import { z } from "zod";
import { StudySubPlanStatus, SuggestionStatus, SubPlanReportStatus } from "./types";

export const SuggestionSchema = z.object({
  id: z.string(),
  subplanId: z.string(),
  studyId: z.string().nullable(),
  type: z.string(),
  data: z.object({
    title: z.string(),
    type: z.enum(["adjacent", "sub-segment"]),
    priority: z.enum(["high", "medium", "low"]),
    reason: z.string(),
    criteria: z.array(z.string()),
    goals: z.array(z.string()),
    dataPoints: z.array(z.string()),
  }),
  quantity: z.number(),
  createdAt: z.date(),
  status: z.nativeEnum(SuggestionStatus),
});

const StudySubPlanBaseSchema = z.object({
  id: z.string(),
  planId: z.string(),
  parentId: z.string().nullable(),
  maxStudy: z.number(),
  suggestions: z.array(SuggestionSchema),
  report: z
    .object({
      id: z.string(),
      subplanId: z.string(),
      createdAt: z.date(),
      status: z.nativeEnum(SubPlanReportStatus),
      content: z.string().optional().nullable(),
    })
    .nullable(),
});

export const GeneratingStudySubPlanSchema = StudySubPlanBaseSchema.extend({
  status: z.literal(StudySubPlanStatus.Generating),
  data: z.object({}),
});

export const CompletedStudySubPlanSchema = StudySubPlanBaseSchema.extend({
  status: z.literal(StudySubPlanStatus.Ready),
  data: z.object({
    title: z.string(),
    approach: z.enum(["adjacent", "sub-segments", "mix of both"]),
    audience: z.string(),
    justification: z.string(),
    considerations: z.array(z.string()),
  }),
});

export const StudySubPlanSchema = z.union([
  GeneratingStudySubPlanSchema,
  CompletedStudySubPlanSchema,
]);

export const StudyPlanSchema = z.object({
  id: z.string(),
  name: z.string(),
  projectId: z.string(),
  audience: z.string(),
  researchGoal: z.string(),
  createdAt: z.date(),
  subplans: z.array(StudySubPlanSchema),
  graphData: z.object({}),
});
