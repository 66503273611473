// Redux Toolkit
import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import { Problem } from "./types";
// Schemas
import { ProblemsSchema } from "./schemas";
// Initial state
import { initialState } from "./initial-state";
// Transport failures
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";

// Create the API slice
export const problemsApi = createApi({
  reducerPath: "problemsApi",
  baseQuery: customBaseQuery,
  tagTypes: ["Problems"],
  endpoints: (builder) => ({
    /***** --- Add Problem Mutation --- *****/
    addProblem: builder.mutation<Problem, { projectId: string; description: string }>({
      query: ({ projectId, description }) => ({
        type: "fetch",
        url: `/problems`,
        method: "POST",
        body: { projectId, description },
      }),
      extraOptions: {
        dataSchema: ProblemsSchema,
      },
    }),
    /***** --- Update Problem Mutation --- *****/
    updateProblem: builder.mutation<
      Problem,
      { projectId: string; problemId: string; description: string }
    >({
      query: ({ projectId, problemId, description }) => ({
        type: "fetch",
        url: `/problems/${problemId}`,
        method: "PUT",
        body: {
          projectId,
          description,
        },
      }),
      extraOptions: {
        dataSchema: ProblemsSchema,
      },
    }),
  }),
});

// Create the regular slice
export const problemsSlice = createSlice({
  name: "problems",
  initialState,
  reducers: {
    /***** --- Reset Problems --- *****/
    resetProblems: () => initialState,
    /***** --- Reset Selected Problems --- *****/
    resetSelectedProblems: (state) => {
      state.data.selectedProblems = [];
    },
    /***** --- Set Project Problems --- *****/
    setProjectProblems: (
      state,
      action: PayloadAction<{ problems?: Problem[]; error?: TransportFailure }>
    ) => {
      state.error = action.payload.error;
      state.data.problems = action.payload.problems;
      state.data.selectedProblems = [];
    },
    /***** --- Set Study Problems --- *****/
    setStudyProblems: (state, action: PayloadAction<{ problems?: Problem[] }>) => {
      state.data.problems = action.payload.problems;
      state.data.selectedProblems = action.payload.problems;
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        isAnyOf(
          problemsApi.endpoints.addProblem.matchPending,
          problemsApi.endpoints.updateProblem.matchPending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          problemsApi.endpoints.addProblem.matchFulfilled,
          problemsApi.endpoints.addProblem.matchRejected,
          problemsApi.endpoints.updateProblem.matchFulfilled,
          problemsApi.endpoints.updateProblem.matchRejected
        ),
        (state) => {
          state.loading = false;
        }
      )
      /***** --- Handle Add Problem Optimistic Update --- *****/
      .addMatcher(problemsApi.endpoints.addProblem.matchPending, (state, action) => {
        const { requestId } = action.meta;
        // create a temporary problem with the request id as the id
        const tempProblem = {
          id: requestId,
          description: action.meta.arg.originalArgs.description,
          createdAt: new Date().toISOString(),
        };
        // add the temporary problem to the problems state
        state.data.problems = [tempProblem, ...(state.data.problems || [])];
        // add the temporary problem to the selectedProblems state
        state.data.selectedProblems = [tempProblem, ...(state.data.selectedProblems || [])];
      })
      // handle server response and replace optimistic update with server data on addProblem
      .addMatcher(problemsApi.endpoints.addProblem.matchFulfilled, (state, action) => {
        const { requestId } = action.meta;
        const createdProblem = action.payload;
        // find the temporary problem in the problems state using the request id
        const tempProblemIndex =
          state.data.problems?.findIndex((problem) => problem.id === requestId) ?? -1;
        // if the temporary problem is found
        if (tempProblemIndex !== -1 && state.data.problems?.[tempProblemIndex]) {
          // replace the temporary problem with the actual problem
          state.data.problems[tempProblemIndex] = createdProblem;
          // replace the temporary selected problem with the actual problem
          state.data.selectedProblems = state.data.selectedProblems?.map((problem) =>
            problem.id === requestId ? createdProblem : problem
          );
        }
      })
      // handle server response and remove temporary problem if error occurs on addProblem
      .addMatcher(problemsApi.endpoints.addProblem.matchRejected, (state, action) => {
        const { requestId } = action.meta;
        // find the temporary problem in the problems state using the request id
        const tempProblemIndex =
          state.data.problems?.findIndex((problem) => problem.id === requestId) ?? -1;
        // if the temporary problem is found
        if (tempProblemIndex !== -1 && state.data.problems?.[tempProblemIndex]?.id) {
          // remove the temporary problem from the problems state
          state.data.problems?.splice(tempProblemIndex, 1);
          // remove the temporary problem from the selectedProblems state
          state.data.selectedProblems = state.data.selectedProblems?.filter(
            (audience) => audience.id !== requestId
          );
        }
      })
      /***** --- Handle Update Problem Fulfilled --- *****/
      .addMatcher(problemsApi.endpoints.updateProblem.matchFulfilled, (state, action) => {
        const updatedProblem = action.payload;

        // Update the problems and selectedProblems with the server data
        state.data.problems = state.data.problems?.map((problem) =>
          problem.id === updatedProblem.id ? updatedProblem : problem
        );
        state.data.selectedProblems = state.data.selectedProblems?.map((problem) =>
          problem.id === updatedProblem.id ? updatedProblem : problem
        );
      })
      /***** --- Handle Errors --- *****/
      .addMatcher(
        isAnyOf(
          problemsApi.endpoints.addProblem.matchRejected,
          problemsApi.endpoints.updateProblem.matchRejected
        ),
        (state, action) => {
          state.error = parseError(action.error);
        }
      );
  },
});

// Export actions
export const { setProjectProblems, setStudyProblems, resetProblems, resetSelectedProblems } =
  problemsSlice.actions;

// export hooks
export const { useAddProblemMutation, useUpdateProblemMutation } = problemsApi;

// Combine the reducers
export const problemsReducer = {
  [problemsApi.reducerPath]: problemsApi.reducer,
  problems: problemsSlice.reducer,
};
